<template lang="html">
    <div>
        <div v-for="(order, index) in orderDetails" :key="index">
            <div class="node" :class="className">
                <div class="node-row" :class="[activeName == order.id ? 'active' : '', order.tags == 'order' ? ' border-location' : '', order.tags == 'order_product' ? ' border-node' : '', order.tags == 'stage' ? ' border-category' : '', order.tags == 'package' ? ' border-sku' : '']">
                    <div class="node-action" @click="order.isOpen = !order.isOpen; parentToggle(order)" v-if="order.tags != 'package'">
                        <i class="fa fa-minus-circle" v-if="order.isOpen"></i>
                        <i class="fa fa-plus-circle" v-else></i>
                    </div>
                    <span class="node-title" @click="order.isOpen = !order.isOpen; parentToggle(order)">
                        {{ order.text }} <small v-if="order.tags == 'order_product'">( Fullkit Due Date : {{ order.fullkit_due_date | moment('DD MMM YYYY') }} ) </small>
                    </span>
                    <a class="editBtn" @click="onSideBar(order)" v-if="order.tags == 'package'">
                        <i class="text-warning fa fa-list"></i>
                    </a>
                    <span v-if="order.tags == 'package'" :class="getFullkitStatusColor(order)">
                        {{ getFullkitStatus(order) }}
                    </span>
                </div>
                <b-order-tree
                    class="child-node"
                    :disableControl="true"
                    :ids="ids"
                    @checkChanged="updateids"
                    v-on="$listeners"
                    :orderDetails="order.orderDetails"
                    v-if="order.isOpen && order.orderDetails && order.orderDetails.length > 0"
                ></b-order-tree>
            </div>
        </div>
    </div>
</template>

<script>
import * as nodeSkuApi from "@common/utils/api/masters/nodeskus.js";
import SkuListSideModal from "@common/components/tree/SkuListSidePanel.vue";
import * as utils from '@connect/utils/utils';

export default {
    name: "tree",
    components: { SkuListSideModal },
    props: {
        orderDetails: [],
        ids: {},
        disableControl: false,
        openSelected: false,
        isCheckboxVisible: true,
        className: "",
    },
    data() {
        return {
            activeName: "",
        };
    },
    watch: {
        ids: function (newVal, oldVal) {
            this.closeAll();
        },
        orderDetails: function (newVal, oldVal) {
            console.log("orderDetails Updates:", this.orderDetails);
        },
    },
    mounted() {
    },
    methods: {
        parentToggle:function(order){
            console.log("Emmited Display:",order);
            //this.toggle(order);
            this.$emit("OnclickChild", order);
            this.$forceUpdate();
        },
        toggle: function (order) {
            if(order.isOpen && order.tags == "order_product") {
                nodeSkuApi
                .fetchSKUsByNodeId(order.id)
                .then((response) => {
                    if (response.data.status.status_code === 0) {
                    let skus = response.data.payload;
                    let filteredSkus = skus.filter((item) => {
                        return item.sales_order_id == sale_order_id.id;
                    });
                    let ordersArr = [];
                    filteredSkus.forEach((element) => {
                        let temp = {
                        checkable: true,
                        id: element.sku,
                        orderDetails: [],
                        parent: order.id,
                        tags: "sku",
                        text: element.skuDetails.name,
                        };
                        ordersArr.push(temp);
                    });
                    //node.nodes = nodesArr;
                    console.log("SKUs : ", order.orderDetails);
                    console.log("Filtered SKUs : ", filteredSkus);
                    }
                })
                .catch((err) => {
                    this.error = err?.response?.data?.payload
                    ? err.response.data.payload
                    : err.message;
                    toasterService.error(err.message);
                });
            }
            this.$forceUpdate();
        },
        closeAll() {
            for (let order of this.orderDetails) {
                this.close(order);
            }
        },
        open: function (order) {
            order.isOpen = true;
            this.$forceUpdate();
        },
        close: function (order) {
            order.isOpen = false;
            this.$forceUpdate();
        },
        updateids(details) {
            this.ids = details[0];
            this.$emit("checkChanged", details);
        },
        onSideBar(order) {
            console.log("order at Side Bar: ", order);
            //this.$refs.skuListSideModal.show(order);
            this.$emit("sideBar", order);
        },
        getFullkitStatus(order){
          console.log("order : ",order);
          let fullkitData = order.report_fullkit_default;
          let fk_allocated_units = fullkitData.fk_allocated_units ? fullkitData.fk_allocated_units : 0;
          let sale_units = fullkitData.sale_units ? fullkitData.sale_units : 0;
          let status = 'N/A';
          let str = '';
          if((fullkitData.unstocked_status == "F" && fullkitData.stocked_status == "F") || (fullkitData.unstocked_status == "N/A" && fullkitData.stocked_status == "F") || (fullkitData.unstocked_status == "F" && fullkitData.stocked_status == "N/A")){
            status = 'F';
            str = 'Fulfilled';
          }
          else if(fullkitData.unstocked_status == "P" || fullkitData.stocked_status == "P"){
            status = 'P';
            str = 'Partial';
          }
          else{
            status = 'I';
            str = 'Incomplete';
          }
          console.log("order `${fk_allocated_units}/${sale_units}` : ",`${fk_allocated_units}/${sale_units}`);
          
          return `Fullkit ${str} (${fk_allocated_units}/${sale_units})`;
        },
        getFullkitStatusColor(order){
          let fullkitData = order.report_fullkit_default;
          let status = 'N/A';
          if((fullkitData.unstocked_status == "F" && fullkitData.stocked_status == "F") || (fullkitData.unstocked_status == "N/A" && fullkitData.stocked_status == "F") || (fullkitData.unstocked_status == "F" && fullkitData.stocked_status == "N/A")){
            status = 'F';
          }
          else if(fullkitData.unstocked_status == "P" || fullkitData.stocked_status == "P"){
            status = 'P';
          }
          else{
            status = 'I';
          }
          return utils.PartialToCSSSpan[status];
        },
    },
};
</script>

<style>
.nodeUiClass {
  /* box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72);
    -webkit-box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72);
    -moz-box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72); */
  margin: 5px 0px;
}
.node {
  position: relative;
}
.node .child-node {
  margin-left: 25px;
  position: relative;
}
.node .child-node::after {
  content: " ";
  height: calc(100% - 5px);
  position: absolute;
  left: -20px;
  width: 0px;
  border: 1px solid;
  border-style: dotted;
  top: -5px;
}
.node .node-action {
  cursor: pointer;
  display: inline-block;
  padding-right: 5px;
}
.node .node-title {
  cursor: pointer;
  display: inline-block;
  padding-left: 5px;
  line-height: 28px;
  margin-left: -5px;
}
.child-node.hide {
  display: none;
}
.child-node .node::after {
  content: " ";
  height: 0;
  position: absolute;
  left: -18px;
  width: 10px;
  border: 1px solid;
  border-style: dotted;
  top: 12px;
}
.node .form-check {
  display: inline-block;
  vertical-align: middle;
  padding-right: 0px;
}
.node .form-check .node-title {
  padding-left: 0;
}
.node .hide-checkbox .form-check {
  display: none !important;
}

.node-row {
  display: inline-block;
  padding: 0px 5px;
  margin: 2px 0px;
  border-radius: 5px;
}
.node-row:active {
  background: aliceblue;
}
.border-location {
  /* background: #1f3863;
    color: #fff; */
  /* border: 2px solid #333; */
  background: #fff;
  color: #203864;
}
.border-node {
  /* background: #4da4f0;
    color: #000; */
  /* border: 2px solid #d65806; */
  background: #fff;
  color: #203864;
}
.border-category {
  /* border: 2px solid #3187f2; */
  /* background: #1f3863;
    color: #fff; */
  /* border: 1px solid #203864; */
  background: #fff;
  color: #203864;
}
.border-sku {
  /* border: 2px solid #d0d0d0; */
  background: #e0dcdc;
  color: #203864;
}
.addBtn {
  margin-left: 1rem;
  padding: 5px;
  cursor: pointer;
}
.editBtn {
  /* margin-left: .5rem; */
  padding: 5px;
  cursor: pointer;
}
</style>
