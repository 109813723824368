<template lang="html">
  <div class="b-location">
    <div class="form-group">
      <label v-if="label">{{label}}</label>
      <div class="form-control" v-bind:class="disabled?'disabled':''" :title="content?content.name:placeholder">{{content?content.name:placeholder}}</div>
      <i class="cil-globe" @click="modalVisible = true" v-bind:class="disabled?'disabled':''"/>
    </div>
    <CModal v-if="modalVisible"
      :title="label?label:(placeholder?placeholder:'Enter values')"
      :show.sync="modalVisible"
      size="lg">
      <CRow>
        <CCol>
          <div>
            <label class="typo__label" v-if="label">Zone/Region</label>
            <multiselect v-model="selectedZone" :options="zones" @input="updateZone" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
          </div>
        </CCol>
        <CCol>
          <div>
            <label class="typo__label" v-if="label">State</label>
            <multiselect v-model="selectedState" :options="states" @input="updateState" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
          </div>
        </CCol>
        <CCol>
          <div>
            <label class="typo__label" v-if="label">City</label>
            <multiselect v-model="selectedCity" :options="cities" @input="updateCity" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
          </div>
        </CCol>
        <CCol>
          <div>
            <label class="typo__label" v-if="label">Area</label>
            <multiselect v-model="selectedArea" :options="areaList" @input="updateArea" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
          </div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton class="btn-danger" @click="close()">Close</CButton>
        <CButton class="btn-success" @click="update()">Update</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import * as locationsAPI from '@common/utils/api/masters/locationType'
export default {
    name: 'BrdgeLocationSelector',
    components: { Multiselect },
    props: ['value', 'label', 'placeholder', 'disabled'],
    data () {
        return {
          locations: [],
          zones: [],
          states: [],
          cities: [],
          areaList: [],
          allZones: [],
          allStates: [],
          allCities: [],
          allAreaList: [],
          selectedZone: {},
          selectedState: {},
          selectedCity: {},
          selectedArea: {},
          zone: {},
          state: {},
          city: {},
          area: {},
          content: {
            id: '',
            name: ''
          },
          modalVisible: false
        }
    },
    watch: { 
        value: function() { // watch it
          console.log("Value id: ", this.value);
          this.content.id = '';
          this.content.name = '';
          if(this.locations.length){
            this.setContent(this.value);
          } else {
            this.getLocations();
          }
        }
    },
    methods: {
      setContent: function(id){
        this.content.id = id;

        let arealist = this.allAreaList;
        let filteredAreaList = arealist.filter(item => {
          return item.id == id;
        });
        if(filteredAreaList.length){
          this.content.name = filteredAreaList[0].name;
          this.selectedArea = filteredAreaList[0];
        } else {
          let citylist = this.allCities;
          let filteredCityList = citylist.filter(item => {
            return item.id == id;
          });
          if(filteredCityList.length){
            this.content.name = filteredCityList[0].name;
            this.selectedCity = filteredCityList[0];
            this.updateCity();
          } else {
            let statelist = this.allStates;
            let filteredStateList = statelist.filter(item => {
              return item.id == id;
            });
            if(filteredStateList.length){
              this.content.name = filteredStateList[0].name;
              this.selectedState = filteredStateList[0];
              this.updateState();
            } else {
              let zonelist = this.allZones;
              let filteredZoneList = zonelist.filter(item => {
                return item.id == id;
              });
              if(filteredZoneList.length){
                this.content.name = filteredZoneList[0].name;
                this.selectedZone = filteredZoneList[0];
                this.updateZone();
              }
            }
          }
        }
        console.log("content id : ", this.content);
      },
      getLocations: function(){
        Promise.allSettled([locationsAPI.getAllLocationTypesWithLocations()]).then(values=>{
          if(values[0].status == 'fulfilled'){
            let list = values[0].value.data.payload;
            this.locations = list;

            let filteredZonesList = list.filter(item => {
              if(item.name){
                return item.name.toLowerCase().trim() == 'zone';
              }
            });
            this.zones = filteredZonesList.length ? filteredZonesList[0].locations : [];
            this.allZones = this.zones;

            let filteredStateList = list.filter(item => {
              if(item.name){
                return item.name.toLowerCase().trim() == 'state';
              }
            });
            this.states = filteredStateList.length ? filteredStateList[0].locations : [];
            this.allStates = this.states;

            let filteredCityList = list.filter(item => {
              if(item.name){
                return item.name.toLowerCase().trim() == 'city';
              }
            });
            this.cities = filteredCityList.length ? filteredCityList[0].locations : [];
            this.allCities = this.cities;

            let filteredAreaList = list.filter(item => {
              if(item.name){
                return item.name.toLowerCase().trim() == 'area';
              }
            });
            this.areaList = filteredAreaList.length ? filteredAreaList[0].locations : [];
            this.allAreaList = this.areaList;

            if(this.value){
              this.setContent(this.value);
            }
          }
        });
      },
      update: function(){
        this.modalVisible = false;
        this.$emit('selectedLocation', this.content);
      },
      close: function(){
        this.modalVisible = false;
        this.setContent(this.value);
      },
      updateZone: function(){
        this.selectedState = {};
        this.selectedCity = {};
        this.selectedArea = {};
        if(this.selectedZone){
          this.content.id = this.selectedZone.id;
          this.content.name = this.selectedZone.name;
          let statelist = this.allStates;
          let filteredStateList = statelist.filter(item => {
            return item.parent_location == this.selectedZone.id;
          });
          this.states = filteredStateList.length ? filteredStateList : [];

          let citylist = this.allCities;
          let filteredCityList = citylist.filter(item => {
            return item.parent_location == this.selectedZone.id;
          });
          this.cities = filteredCityList.length ? filteredCityList : [];

          let arealist = this.allAreaList;
          let filteredAreaList = arealist.filter(item => {
            return item.parent_location == this.selectedZone.id;
          });
          this.areaList = filteredAreaList.length ? filteredAreaList : [];
        } else {
          this.content = {
            id: '',
            name: ''
          };
          this.states = this.allStates;
          this.cities = this.allCities;
          this.areaList = this.allAreaList;
        }
      },
      updateState: function(){
        this.selectedCity = {};
        this.selectedArea = {};
        if(this.selectedState){
          this.content.id = this.selectedState.id;
          this.content.name = this.selectedState.name;
          let citylist = this.allCities;
          let filteredCityList = citylist.filter(item => {
            return item.parent_location == this.selectedState.id;
          });
          this.cities = filteredCityList.length ? filteredCityList : [];

          let arealist = this.allAreaList;
          let filteredAreaList = arealist.filter(item => {
            return item.parent_location == this.selectedState.id;
          });
          this.areaList = filteredAreaList.length ? filteredAreaList : [];

          let zonelist = this.allZones;
          let filteredZoneList = zonelist.filter(item => {
            return item.id == this.selectedState.parent_location;
          });
          this.selectedZone = filteredZoneList.length ? filteredZoneList[0] : {};
        } else {
          this.content = {
            id: '',
            name: ''
          };
          this.cities = this.allCities;
          this.areaList = this.allAreaList;
        }
      },
      updateCity: function(){
        this.selectedArea = {};
        if(this.selectedCity){
          this.content.id = this.selectedCity.id;
          this.content.name = this.selectedCity.name;
          let arealist = this.allAreaList;
          let filteredAreaList = arealist.filter(item => {
            return item.parent_location == this.selectedCity.id;
          });
          this.areaList = filteredAreaList.length ? filteredAreaList : [];

          let statelist = this.allStates;
          let filteredStateList = statelist.filter(item => {
            return item.id == this.selectedCity.parent_location;
          });
          this.selectedState = filteredStateList.length ? filteredStateList[0] : {};

          let zonelist = this.allZones;
          let filteredZoneList = zonelist.filter(item => {
            return item.id == this.selectedState.parent_location;
          });
          this.selectedZone = filteredZoneList.length ? filteredZoneList[0] : {};
        } else {
          this.content = {
            id: '',
            name: ''
          };
          this.areaList = this.allAreaList;
        }
      },
      updateArea: function(){
        if(this.selectedArea){
          this.content.id = this.selectedArea.id;
          this.content.name = this.selectedArea.name;

          let citylist = this.allCities;
          let filteredCityList = citylist.filter(item => {
            return item.id == this.selectedArea.parent_location;
          });
          this.selectedCity = filteredCityList.length ? filteredCityList[0] : {};

          let statelist = this.allStates;
          let filteredStateList = statelist.filter(item => {
            return item.id == this.selectedCity.parent_location;
          });
          this.selectedState = filteredStateList.length ? filteredStateList[0] : {};

          let zonelist = this.allZones;
          let filteredZoneList = zonelist.filter(item => {
            return item.id == this.selectedState.parent_location;
          });
          this.selectedZone = filteredZoneList.length ? filteredZoneList[0] : {};
        } else {
          this.content = {
            id: '',
            name: ''
          };
        }
      }
    },
    mounted: function(){
      //this.content = this.value;
      this.getLocations();
    }
}
</script>

<style lang="css" scoped>
.b-location .form-group {
  position: relative;
  margin-bottom: 0 !important;
}
.b-location .form-group .form-control{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 35px;
}
.b-location .form-group i{
  font-size: 24px;
  position: absolute;
  bottom: 0;
  right: 0px;
  cursor: pointer;
  height: 38px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  width: 35px;
  padding-left: 5px;
  border-left: 1px solid #ced4da;
}
.b-location .multiselect__content-wrapper {
    width: auto !important;
}
</style>
