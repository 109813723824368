<template lang="html">
    <div style="width:100%;">
        <table :id="id" class="table table-bordered" cellspacing="0" width="100%"></table>
    </div>
</template>
<script>
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-responsive-dt'
import 'datatables.net-fixedcolumns-dt'
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
import 'datatables.net-fixedcolumns-dt/css/fixedColumns.dataTables.min.css'
import * as loaderService from "@common/service/loader.service";
import axios from 'axios'
import $ from 'jquery';
export default {
  name: "grid",
  components:{},
  props:['serverUrl', 'columnDefs', 'rowClassRules', 'fixedColumns', 'responsive', 'rowselection', 'rowid', 'processFn', 'pagination', 'defaultSortColumn', 'defaultSortDirection', 'defaultSearchStr', 'defaultSearchColumn', 'scrollY', 'redrawTable', 'zoneFilter', 'availabilityZone', 'requiredquantity'],
  watch: {},
  data () {
    return {
      id: new Date().getTime(),
      datatable:null,
      donotcallserver:false,
      selectedRows:{},
      orowId:'id',
      opaging: true,
      data:[],
      search:{},
      defaultSortColumnIndex:0,
      defaultDir: 'asc',
      appliedFilters: {},
      selectedQuantities: []
    }
  },
  watch: { 
      columnDefs: function(newVal, oldVal) { // watch it
        if(newVal && oldVal && newVal.length != oldVal.length){
          this.destoryAndDraw();
        }
      },
      redrawTable: function() {
        this.destoryAndDraw();
      },
      serverUrl: function(){
        this.destoryAndDraw();
      },
      requiredquantity: function(){
        //console.log("requiredquantity Watch : ", this.requiredquantity);
        this.destoryAndDraw();
      }
  },
  mounted (){
    //console.log("mounted ");
    if(!this.defaultSortDirection){
      this.defaultDir = 'asc';
    } else {
      this.defaultDir = this.defaultSortDirection;
    }
    this.dataTableInitialize();
  },
  methods: {
    dataTableInitialize(){
      //console.log("Grid Table dataTableInitialize");
      let gridObj = this;
      if(this.rowid){
        this.orowId = this.rowid;
      }
      if(this.pagination != undefined){
        this.opaging = this.pagination;
      }
      if(this.rowselection){
        this.columnDefs[0].renderOg = this.columnDefs[0].render;
        let quantity = this.requiredquantity != undefined ? this.requiredquantity : 0;
        this.columnDefs[0].render = function(data, type, row, settings){
          //console.log("data : ", data)
          //console.log("type : ", type)
          //console.log("Row : ", row)
          //console.log("settings : ", settings)
              $(`#${gridObj.id}`).on('change', '.select-checkbox',function(ev){
                  ev.stopImmediatePropagation();
                  let el = $(this);
                  let tr = el.closest('tr');
                  let id = $(tr).attr('id');
                  console.log("$(tr) : ", id);
                  if(el.is(':checked')){
                    let rowindex = tr.index().toString();
                    tr.addClass("row-selected");
                    gridObj.selectedRows[id] = gridObj.datatable.rows().data()[rowindex];
                  }
                  else{
                    tr.removeClass("row-selected");
                    delete gridObj.selectedRows[id];
                  }
                  gridObj.onSelectionChanged();
              });
              let checked = "";
              let id = "";
              if(row && type){
                id = settings.settings.rowIdFn(row, type);
                if(gridObj.selectedRows[id]){
                  checked = "checked";
                }
              }
              if(quantity > 0){
                quantity = quantity - parseFloat(row.available_quantity);
                id = settings.settings.rowIdFn(row, type);
                checked = "checked";
                let tr = $(`#${gridObj.id}`).find('tr');
                tr.addClass("row-selected");
                gridObj.selectedRows[id] = gridObj.datatable.rows().data()[settings.row];
              }
              let renderText = gridObj.columnDefs[0].renderOg ? gridObj.columnDefs[0].renderOg(data, type, row, settings) : data;
              console.log("gridObj : ", gridObj);
              console.log("gridObj id : ", id);
              return `<input class="select-checkbox" type="checkbox" id="${gridObj.id}-${id}" ${checked}> ${renderText}`;
            }
      }
      $.fn.dataTable.ext.errMode = 'none';
      $("#"+this.id).on( 'error.dt', function ( e, settings, techNote, message ) {
          console.log( 'An error has been reported by DataTables: ', message );
      });
      for(let ci=0; ci < this.columnDefs.length; ci++){
        // this.columnDefs[ci].orderable = true;
        // this.columnDefs[ci].targets = 0;
        if(!this.columnDefs[ci].defaultContent){
          this.columnDefs[ci].defaultContent = "";
        }
        if(this.columnDefs[ci].permission){
          if(window?.keycloakImplObj?.userDetails?.permissions && !window.keycloakImplObj.userDetails.permissions.includes(this.columnDefs[ci].permission)){
            this.columnDefs[ci].visible = false;
          }
        }
        if(this.defaultSortColumn){
          if(this.defaultSortColumn == this.columnDefs[ci].data){
            this.defaultSortColumnIndex = ci;
          }
        }
        if(this.defaultSearchStr){
          if(this.defaultSearchStr !== null){
            this.search[this.defaultSearchColumn] = this.defaultSearchStr;
          }
        }
        if(this.zoneFilter){
          this.search['availability_zone'] = parseInt(this.availabilityZone);
        }
      }
      /* if(!this.defaultSortDirection){
        this.defaultSortDirection = 'asc';
      } */
      //console.log("requiredquantity : ", gridObj.requiredquantity);
      let quantity = gridObj.requiredquantity != undefined ? gridObj.requiredquantity : 0;
      //console.log("quantity : ", quantity);
      let reqQ = quantity;
      let datatableConfigs = {
        autoWidth: false,
        destroy: true,
        processing: true,
        paging: this.opaging,
        searching: false,
        ordering: true,
        select: {
          style: 'os',
          selector: 'td:first-child'
        },
        order: [[this.defaultSortColumnIndex, this.defaultDir]],   
        orderable: false,
        className: 'select-checkbox',
        targets: 0,     
        scrollX:true,
        scrollCollapse: true,
        columns: this.columnDefs,
        // rowReorder: {
        //     selector: 'tr',
        //     update: true
        // }, 
        pagingType:"full_numbers",
        rowId: this.orowId,
        fnCreatedRow: function (nRow, aData, iDataIndex, tdLists, settings) {
          if(aData.item_id){
            let selectedQ = reqQ >= 0 ? reqQ : 0;
            let dataQua = aData.available_quantity != undefined ? parseFloat(aData.available_quantity) : 0;
            if(reqQ > dataQua){
              selectedQ = dataQua;
            }
            reqQ = reqQ - dataQua;
            
            aData.required_quantity = selectedQ;
          }
          
          let rowClass = "";
          let tr = $(nRow);
          if(gridObj.selectedRows[tr.attr('id')]){
            gridObj.selectedRows[tr.attr('id')] = aData;
            rowClass = "row-selected";
          }
          if(aData.isChild){
            rowClass = rowClass + " child-row";
          }
          if(gridObj.rowClassRules){
            for(let key in gridObj.rowClassRules){
              if(gridObj.rowClassRules[key] && gridObj.rowClassRules[key](aData)){
                rowClass = rowClass +" "+key;
              }
            }
          }
          if(aData.follow_up_date != undefined){
            let d = new Date();
            let currentDate = d.toISOString();
            //console.log("currentDate : ", currentDate);
            //console.log("aData.follow_up_date : ", aData.follow_up_date);
            if(currentDate > aData.follow_up_date && aData.is_completed != true){
              rowClass = rowClass + " passedDates";
            }
            if(currentDate > aData.follow_up_date && aData.is_completed == true){
              rowClass = rowClass + " upcomingDates";
            }
            if(new Date(aData.follow_up_date).toISOString().slice(0, 10) == d.toISOString().slice(0, 10)) {
              rowClass = rowClass + " currentDates";
            }
          }
          
          if(rowClass){
            tr.addClass(rowClass);
          }
          if(quantity > 0){
            quantity = quantity - parseFloat(aData.available_quantity);
            //console.log("quantity 1 : ", parseInt(aData.available_quantity));
            //console.log("quantity 2 : ", quantity);
            if(gridObj.rowClassRules){
              let classess = "";
              for(let key in gridObj.rowClassRules){
                if(gridObj.rowClassRules[key] && gridObj.rowClassRules[key](aData)){
                  classess = classess +" "+key;
                }
              }
              $("#"+gridObj.id+" tr").removeClass(classess);
              tr.addClass(classess);
            }
          }

          tr.on('click', function($event, b, c){
            //console.log("Datatable Row Clicked : ", $event);
            setTimeout(() => {
              if(gridObj.rowClassRules){
                let classess = "";
                for(let key in gridObj.rowClassRules){
                  if(gridObj.rowClassRules[key] && gridObj.rowClassRules[key](aData)){
                    classess = classess +" "+key;
                  }
                }
                $("#"+gridObj.id+" tr").removeClass(classess);
                tr.addClass(classess);
              }
            }, 300);
            gridObj.$emit("row-clicked", aData);
          });

          tr.find('.quantity').on("change paste keyup", function ($event) {
            //console.log("Datatable Row Input Clicked : ", $event);
            //console.log("Datatable Row Input Clicked aData: ", aData);
            let clickedData = {};
            if(aData.order_product_id){
              clickedData = {
                id: aData.id,
                order_product_id: aData.order_product_id,
                sale_order_id: aData.sale_order_id,
                fullkit_date: $event.target.value
              };
            }
            if(aData.item_id || aData.batch_id){
              aData.required_quantity = parseFloat($event.target.value);
              gridObj.selectedQuantities[iDataIndex] = aData.required_quantity;
              //console.log("gridObj.selectedQuantities 1111 : ", gridObj.selectedQuantities);
              let selectedQuantity = 0;
              for(let i of gridObj.selectedQuantities){
                selectedQuantity = selectedQuantity + i;
              }
              //console.log("selectedQuantity : ", selectedQuantity);
              //console.log("gridObj.requiredquantity : ", gridObj.requiredquantity);
              if(parseFloat(gridObj.requiredquantity) >= selectedQuantity){
                clickedData = {
                  id: aData.id,
                  quantity: aData.required_quantity
                };
                let id = $(this).parent().parent().attr('id');
                //console.log("gridObj.id : ", id);
                if(id == aData.id) {
                  if(aData.required_quantity > 0) {
                    $(this).parent().parent().find('input').prop('checked', true);
                    let rowindex = tr.index().toString();
                    tr.addClass("row-selected");
                    //let id = tr.attr('id');
                    gridObj.datatable.rows().data()[rowindex].required_quantity = aData.required_quantity;
                    gridObj.selectedRows[id] = aData;
                  } else {
                      $(this).parent().parent().find('input').prop('checked', false);
                      tr.removeClass("row-selected");
                      delete gridObj.selectedRows[id];
                  }
                }
              } else {
                let id = $(this).parent().parent().attr('id');
                $(this).parent().parent().find('input').prop('checked', false);
                tr.removeClass("row-selected");
                delete gridObj.selectedRows[id];
              }
            }
            //console.log("clickedData : ", clickedData);
            gridObj.$emit("row-input-clicked", clickedData);
            gridObj.onSelectionChanged();
          });

          tr.find('.required_quantity').on("change paste keyup", function ($event) {
            let required_quantity = $event.target.value;
            gridObj.$emit("required-quantity", required_quantity);
            gridObj.onSelectionChanged();
          });

          tr.find('.assigned_to_node_dropdown').on("change paste keyup", function ($event) {
            let selected_node_id = $event.target.value;
            let clickedData = {};
            if(aData.order_product_id){
              clickedData = {
                id: aData.id,
                order_product_id: aData.order_product_id,
                sale_order_id: aData.sale_order_id,
                selected_node_id: selected_node_id
              };
            }
            gridObj.$emit("selected_node_id", clickedData);
            gridObj.onSelectionChanged();
          });
          gridObj.onSelectionChanged();
        },
        oLanguage:{
          sInfo : "<b>_START_</b> to <b>_END_</b> of <b>_TOTAL_</b>",
          oPaginate: {
            sFirst: "<i class='fa fa-angle-double-left'></i>",
            sPrevious: "<i class='fa fa-angle-left'></i>",
            sNext: "<i class='fa fa-angle-right'></i>",
            sLast: "<i class='fa fa-angle-double-right'></i>"
          }
        },
        //dom: '<"top"flp<"clear">>rt<"bottom"ifp<"clear">>',
        dom: '<"datatable-bottom"fl<"row-selection-div">ip>Bfrt<"datatable-bottom no-border-top"fl<"row-selection-div">ip>',
        responsive: this.responsive,
        serverSide: true,
        sAjaxSource: this.serverUrl,
        fnServerData: function(sSource, aoData, fnCallback) {
          //console.log("fnServerData #####", gridObj);
          //console.log("fnServerData datatableConfigs #####", datatableConfigs);
          // if(datatableConfigs.rowReorder){
          //   gridObj.donotcallserver = true;
          // }
          if(gridObj.donotcallserver) {
            fnCallback(gridObj.data);
            gridObj.donotcallserver=false;
            return;
          }
          //console.log("fnServerData #####", gridObj);
          loaderService.showLoader();
          gridObj.selectedRows = {};
          gridObj.onSelectionChanged();
          gridObj.$emit("selection-changed", []);
          $(".row-selected").removeClass("row-selected");
          let tempInputData={};
          for(let data of aoData) {
            if(data.value) {
              tempInputData[data.name]=data.value;
            }
          }
          let inputData={
            sortColumn: tempInputData.iSortCol_0? gridObj.columnDefs[tempInputData.iSortCol_0].data:gridObj.columnDefs[gridObj.defaultSortColumnIndex].data,
            sortDirection: tempInputData.sSortDir_0||gridObj.defaultDir,
            search: gridObj.search,
            displayLength: tempInputData.iDisplayLength||10,
            displayStart: tempInputData.iDisplayStart||0
          };
          gridObj.appliedFilters = inputData;
          gridObj.appliedFilters.sourceUrl = sSource;
          axios.post(sSource, inputData)
            .then((response) => {
              //if(response.data.status.status_code===0) {
                gridObj.data=response.data.payload;
                if(gridObj.processFn){
                  gridObj.data = gridObj.processFn(gridObj.data);
                }
                fnCallback(gridObj.data);
                gridObj.$emit("loaded", gridObj.data);
                gridObj.$emit("reportloaded", gridObj);
                gridObj.$emit("searched", gridObj.appliedFilters);
                //gridObj.donotcallserver = true;
                loaderService.hideLoader();
              //}
            })
            .catch((err) => {
              console.log(err);
              loaderService.hideLoader();
            });
        }
      };
      if(this.scrollY){
        datatableConfigs.scrollY = this.scrollY;
      }
      if(this.fixedColumns){
        datatableConfigs.fixedColumns = this.fixedColumns;
      }
      //console.log("datatableConfigs ####", datatableConfigs);
      //datatableConfigs.rowReorder = true;
      // datatableConfigs.rowReorder = {
      //       dataSrc: 'str'
      //   }
      gridObj.datatable = $("#"+this.id).DataTable(datatableConfigs);

      // gridObj.datatable.on("click", "th.select-checkbox", function() {
      //     if ($("th.select-checkbox").hasClass("selected")) {
      //         gridObj.datatable.rows().deselect();
      //         $("th.select-checkbox").removeClass("selected");
      //     } else {
      //         gridObj.datatable.rows().select();
      //         $("th.select-checkbox").addClass("selected");
      //     }
      // }).on("select deselect", function() {
      //     ("Some selection or deselection going on")
      //     if (gridObj.datatable.rows({
      //             selected: true
      //         }).count() !== gridObj.datatable.rows().count()) {
      //         $("th.select-checkbox").removeClass("selected");
      //     } else {
      //         $("th.select-checkbox").addClass("selected");
      //     }
      // });
      gridObj.datatable.columns().flatten().each(function (colID) {
          let classname = "column-header";
          if(gridObj.rowselection && colID == 0){
            classname = classname + " select-header"
          }
          if(gridObj.columnDefs[colID].classname !== undefined){
            classname = classname + " " + gridObj.columnDefs[colID].classname;
          }
          var $headerDiv = $(`<div class="${classname}">
                                <label class="column-title">${gridObj.columnDefs[colID].title || ""}</label>
                              </div>
                            `);
          if(gridObj.columnDefs[colID].floatingFilter == undefined || gridObj.columnDefs[colID].floatingFilter){
            var $headerDiv = $(`<div class="${classname}">
                                  <label class="column-title">${gridObj.columnDefs[colID].title || ""}</label>
                                  <div class="search-main-div">
                                    <i class="fa fa-filter"></i>
                                    <div class="search-div">
                                      <input data-columnname="${gridObj.columnDefs[colID].data}"/>
                                      <button class="btn btn-clear" data-columnname="${gridObj.columnDefs[colID].data}">
                                        <i class="fa fa-times"></i>
                                      </button>
                                      <button class="btn btn-search" data-columnname="${gridObj.columnDefs[colID].data}">
                                        <i class="fa fa-search"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              `);
            $headerDiv.find('.fa-filter').on('click',function($event, b, c){
                $event.stopImmediatePropagation();
                $(".show-search").removeClass("show-search");
                let currentEl = $($event.currentTarget);
                currentEl.parent().addClass("show-search");
                let bodyFn = function($event) {
                  if (!$($event.target).closest('.search-main-div').length) {
                    $(".show-search").removeClass("show-search");
                  }
                  else{
                    $( "body" ).one( "click", bodyFn);
                  }
                }
                $( "body" ).one( "click", bodyFn);
            });
            $headerDiv.find('input').on('focus', function($event){
                gridObj.donotcallserver = true;
                $event.stopImmediatePropagation();
            });
            $headerDiv.find('input').keypress(function ($event) {
              $event.stopImmediatePropagation();
              var key = $event.which;
              if(key == 13) {
                gridObj.search[$event.currentTarget.dataset.columnname] = $event.currentTarget.value;
                gridObj.datatable.draw();
                return false;  
              }
            });
            $headerDiv.find('.btn-search').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.search[$event.currentTarget.dataset.columnname] = $event.currentTarget.parentNode.children[0].value;
                gridObj.$emit("searched", gridObj);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.btn-clear').click(function($event, b, c){
                $event.stopImmediatePropagation();
                $(".show-search").removeClass("show-search");
                $event.currentTarget.parentNode.children[0].value = '';
                delete gridObj.search[$event.currentTarget.dataset.columnname];
                gridObj.$emit("searched", gridObj);
                gridObj.datatable.draw();
            });
            $(document).delegate('a.search-filter-close-btn', 'click', function(e){
              //e.preventDefault();
              e.stopImmediatePropagation();
              var data = $(this).data("id");
              $(".show-search").removeClass("show-search");
              delete gridObj.search[data];
              //gridObj.$emit("searched", gridObj);
              gridObj.datatable.draw();
            });
            // $(document).delegate('a.skuNamecls', 'click', function(e){
            //   e.preventDefault();
            //   e.stopImmediatePropagation();
            //   var data = $(this).data("id");
            //   $(".show-search").removeClass("show-search");
            //   delete gridObj.search[data];
            //   //gridObj.$emit("searched", gridObj);
            //   gridObj.datatable.draw();
            // });
            // $(document).delegate('a.suppliercls', 'click', function(e){
            //   e.preventDefault();
            //   e.stopImmediatePropagation();
            //   var data = $(this).data("id");
            //   $(".show-search").removeClass("show-search");
            //   delete gridObj.search[data];
            //   //gridObj.$emit("searched", gridObj);
            //   gridObj.datatable.draw();
            // });
            // $(document).delegate('a.buyingNodecls', 'click', function(e){
            //   e.preventDefault();
            //   e.stopImmediatePropagation();
            //   var data = $(this).data("id");
            //   $(".show-search").removeClass("show-search");
            //   delete gridObj.search[data];
            //   //gridObj.$emit("searched", gridObj);
            //   gridObj.datatable.draw();
            // });
            // $(document).delegate('a.skuUomcls', 'click', function(e){
            //   e.preventDefault();
            //   e.stopImmediatePropagation();
            //   var data = $(this).data("id");
            //   $(".show-search").removeClass("show-search");
            //   delete gridObj.search[data];
            //   //gridObj.$emit("searched", gridObj);
            //   gridObj.datatable.draw();
            // });
            // $(document).delegate('a.availability_zone', 'click', function(e){
            //   e.preventDefault();
            //   e.stopImmediatePropagation();
            //   var data = $(this).data("id");
            //   $(".show-search").removeClass("show-search");
            //   delete gridObj.search[data];
            //   //gridObj.$emit("searched", gridObj);
            //   gridObj.datatable.draw();
            // });
          }

          if(gridObj.columnDefs[colID].zoneFilter != undefined && gridObj.columnDefs[colID].zoneFilter){
            var $headerDiv = $(`<div class="${classname}">
                                  <label class="column-title">${gridObj.columnDefs[colID].title || ""}</label>
                                  <div class="search-main-div">
                                    <i class="fa fa-filter"></i>
                                    <div class="search-div-zone">
                                      <div class="availzones">
                                        <button class="btn allbtn zonebtn" data-columnname="availability_zone">All</button>
                                        <button class="btn blackbtn btntext zonebtn" data-columnname="availability_zone">Stockout</button>
                                        <button class="btn redbtn btntext zonebtn" data-columnname="availability_zone">Critical</button>
                                        <button class="btn yellowbtn btntext zonebtn" data-columnname="availability_zone">Optimum</button>
                                        <button class="btn greenbtn btntext zonebtn" data-columnname="availability_zone">Safe</button>
                                        <button class="btn bluebtn btntext zonebtn" data-columnname="availability_zone">Surplus</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              `);
            $headerDiv.find('.fa-filter').on('click',function($event, b, c){
                $event.stopImmediatePropagation();
                $(".show-search").removeClass("show-search");
                let currentEl = $($event.currentTarget);
                currentEl.parent().addClass("show-search");
                let bodyFn = function($event) {
                  if (!$($event.target).closest('.search-main-div').length) {
                    $(".show-search").removeClass("show-search");
                  }
                  else{
                    $( "body" ).one( "click", bodyFn);
                  }
                }
                $( "body" ).one( "click", bodyFn);
            });
            $headerDiv.find('.allbtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                if(typeof gridObj.appliedFilters.search['availability_zone'] != undefined){
                  delete gridObj.appliedFilters.search['availability_zone'];
                }
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.blackbtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['availability_zone'] = 0;
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.redbtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['availability_zone'] = 1;
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.yellowbtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['availability_zone'] = 2;
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.greenbtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['availability_zone'] = 3;
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.bluebtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['availability_zone'] = 4;
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
          }

          if(gridObj.columnDefs[colID].prstatusFilter != undefined && gridObj.columnDefs[colID].prstatusFilter){
            var $headerDiv = $(`<div class="${classname}">
                                  <label class="column-title">${gridObj.columnDefs[colID].title || ""}</label>
                                  <div class="search-main-div">
                                    <i class="fa fa-filter"></i>
                                    <div class="search-div-zone">
                                      <button class="btn btn-info allStatusBtn prstatusbtn" data-columnname="pr_status">All</button>
                                      <button class="btn btn-secondary draftStatusBtn prstatusbtn" data-columnname="pr_status">Draft</button>
                                      <button class="btn btn-secondary forApprovalStatusBtn prstatusbtn" data-columnname="pr_status">For Approval</button>
                                      <button class="btn btn-success approvedStatusBtn prstatusbtn" data-columnname="pr_status">Approved</button>
                                      <button class="btn btn-secondary forRevisionStatusBtn prstatusbtn" data-columnname="pr_status">For Revision</button>
                                      <button class="btn btn-primary revisedStatusBtn prstatusbtn" data-columnname="pr_status">Revised</button>
                                      <button class="btn btn-danger cancelledStatusBtn prstatusbtn" data-columnname="pr_status">Cancelled</button>
                                      <button class="btn btn-success closedStatusBtn prstatusbtn" data-columnname="pr_status">Closed</button>
                                    </div>
                                  </div>
                                </div>
                              `);
            $headerDiv.find('.fa-filter').on('click',function($event, b, c){
                $event.stopImmediatePropagation();
                $(".show-search").removeClass("show-search");
                let currentEl = $($event.currentTarget);
                currentEl.parent().addClass("show-search");
                let bodyFn = function($event) {
                  if (!$($event.target).closest('.search-main-div').length) {
                    $(".show-search").removeClass("show-search");
                  }
                  else{
                    $( "body" ).one( "click", bodyFn);
                  }
                }
                $( "body" ).one( "click", bodyFn);
            });
            $headerDiv.find('.allStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                if(typeof gridObj.appliedFilters.search['pr_status'] != undefined){
                  delete gridObj.appliedFilters.search['pr_status'];
                }
                gridObj.datatable.draw();
            });
            $headerDiv.find('.draftStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['pr_status'] = '1';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.forApprovalStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['pr_status'] = '2';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.approvedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['pr_status'] = '3';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.cancelledStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['pr_status'] = '4';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.forRevisionStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['pr_status'] = '5';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.revisedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['pr_status'] = '6';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.closedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['pr_status'] = '7';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
          }

          if(gridObj.columnDefs[colID].postatusFilter != undefined && gridObj.columnDefs[colID].postatusFilter){
            var $headerDiv = $(`<div class="${classname}">
                                  <label class="column-title">${gridObj.columnDefs[colID].title || ""}</label>
                                  <div class="search-main-div">
                                    <i class="fa fa-filter"></i>
                                    <div class="search-div-zone">
                                      <button class="btn allbtn zonebtn allStatusBtn" data-columnname="po_status">All</button>
                                        <button class="btn blackbtn btntext zonebtn draftStatusBtn" data-columnname="po_status">Draft</button>
                                        <button class="btn bluebtn btntext zonebtn forApprovalStatusBtn" data-columnname="po_status">Submitted</button>
                                        <button class="btn greenbtn btntext zonebtn approvedStatusBtn" data-columnname="po_status">Approved</button>
                                        <button class="btn yellowbtn btntext zonebtn forRevisionStatusBtn" data-columnname="po_status">Revision</button>
                                        <button class="btn bluebtn btntext zonebtn revisedStatusBtn" data-columnname="po_status">Revised</button>
                                        <button class="btn yellowbtn btntext zonebtn reviewStatusBtn" data-columnname="po_status">Review</button>
                                        <button class="btn bluebtn btntext zonebtn reviewedStatusBtn" data-columnname="po_status">Reviewed</button>
                                        <button class="btn redbtn btntext zonebtn cancelledStatusBtn" data-columnname="po_status">Cancelled</button>
                                        <!--<button class="btn blackbtn btntext zonebtn closedStatusBtn" data-columnname="po_status">Closed</button>-->
                                    </div>
                                  </div>
                                </div>
                              `);
            $headerDiv.find('.fa-filter').on('click',function($event, b, c){
                $event.stopImmediatePropagation();
                $(".show-search").removeClass("show-search");
                let currentEl = $($event.currentTarget);
                currentEl.parent().addClass("show-search");
                let bodyFn = function($event) {
                  if (!$($event.target).closest('.search-main-div').length) {
                    $(".show-search").removeClass("show-search");
                  }
                  else{
                    $( "body" ).one( "click", bodyFn);
                  }
                }
                $( "body" ).one( "click", bodyFn);
            });
            $headerDiv.find('.allStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                if(typeof gridObj.appliedFilters.search['po_status'] != undefined){
                  delete gridObj.appliedFilters.search['po_status'];
                }
                gridObj.datatable.draw();
            });
            $headerDiv.find('.draftStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'draft';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.forApprovalStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'for_approv';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.approvedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'approved';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.cancelledStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'cancelled';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.forRevisionStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'for_revis';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.revisedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'revised';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.closedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'closed';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.reviewStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'review';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.reviewedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'reviewed';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
          }

          if(gridObj.columnDefs[colID].pogrnstatusFilter != undefined && gridObj.columnDefs[colID].pogrnstatusFilter){
            var $headerDiv = $(`<div class="${classname}">
                                  <label class="column-title">${gridObj.columnDefs[colID].title || ""}</label>
                                  <div class="search-main-div">
                                    <i class="fa fa-filter"></i>
                                    <div class="search-div-zone">
                                      <button class="btn allbtn zonebtn allStatusBtn" data-columnname="po_status">All</button>
                                        <button class="btn greenbtn btntext zonebtn approvedStatusBtn" data-columnname="po_status">Approved</button>
                                        <button class="btn bluebtn btntext zonebtn fulfilledStatusBtn" data-columnname="po_status">Fulfilled</button>
                                    </div>
                                  </div>
                                </div>
                              `);
            $headerDiv.find('.fa-filter').on('click',function($event, b, c){
                $event.stopImmediatePropagation();
                $(".show-search").removeClass("show-search");
                let currentEl = $($event.currentTarget);
                currentEl.parent().addClass("show-search");
                let bodyFn = function($event) {
                  if (!$($event.target).closest('.search-main-div').length) {
                    $(".show-search").removeClass("show-search");
                  }
                  else{
                    $( "body" ).one( "click", bodyFn);
                  }
                }
                $( "body" ).one( "click", bodyFn);
            });
            $headerDiv.find('.allStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                if(typeof gridObj.appliedFilters.search['po_status'] != undefined){
                  delete gridObj.appliedFilters.search['po_status'];
                }
                gridObj.datatable.draw();
            });
            $headerDiv.find('.approvedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'approved';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.fulfilledStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['po_status'] = 'fulfilled';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
          }

          if(gridObj.columnDefs[colID].grnstatusFilter != undefined && gridObj.columnDefs[colID].grnstatusFilter){
            var $headerDiv = $(`<div class="${classname}">
                                  <label class="column-title">${gridObj.columnDefs[colID].title || ""}</label>
                                  <div class="search-main-div">
                                    <i class="fa fa-filter"></i>
                                    <div class="search-div-zone">
                                      <button class="btn allbtn zonebtn allStatusBtn" data-columnname="is_draft">All</button>
                                        <button class="btn greenbtn btntext zonebtn approvedStatusBtn" data-columnname="is_draft">Received</button>
                                        <button class="btn bluebtn btntext zonebtn fulfilledStatusBtn" data-columnname="is_draft">Open</button>
                                        <button class="btn redbtn btntext zonebtn cancelledStatusBtn" data-columnname="is_draft">Cancel</button>
                                    </div>
                                  </div>
                                </div>
                              `);
            $headerDiv.find('.fa-filter').on('click',function($event, b, c){
                $event.stopImmediatePropagation();
                $(".show-search").removeClass("show-search");
                let currentEl = $($event.currentTarget);
                currentEl.parent().addClass("show-search");
                let bodyFn = function($event) {
                  if (!$($event.target).closest('.search-main-div').length) {
                    $(".show-search").removeClass("show-search");
                  }
                  else{
                    $( "body" ).one( "click", bodyFn);
                  }
                }
                $( "body" ).one( "click", bodyFn);
            });
            $headerDiv.find('.allStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                if(typeof gridObj.appliedFilters.search['is_draft'] != undefined){
                  delete gridObj.appliedFilters.search['is_draft'];
                }
                gridObj.datatable.draw();
            });
            $headerDiv.find('.approvedStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['is_draft'] = 'close';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.fulfilledStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['is_draft'] = 'open';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
            $headerDiv.find('.cancelledStatusBtn').click(function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.appliedFilters.search['is_draft'] = 'delete';
                gridObj.$emit("searched", gridObj.appliedFilters);
                gridObj.datatable.draw();
            });
          }

          if(gridObj.columnDefs[colID].floatingFilterExcel != undefined && gridObj.columnDefs[colID].floatingFilterExcel){
            var $headerDiv = $(`<div class="${classname}">
                                  <label class="column-title">${gridObj.columnDefs[colID].title || ""}</label>
                                  <div class="search-main-div">
                                    <i class="fa fa-cloud-download"></i>
                                  </div>
                                </div>
                              `);
            $headerDiv.find('.fa-cloud-download').on('click',function($event, b, c){
                $event.stopImmediatePropagation();
                gridObj.$emit("excelclicked", gridObj.columnDefs[colID].nodeID);
            });
          }

          $(gridObj.datatable.column(colID).header()).html("");
          $headerDiv.appendTo(gridObj.datatable.column(colID).header());
          //console.log("Header 1 : ", gridObj.columnDefs.length, colID, $(gridObj.datatable.column(colID).header()));
          $(gridObj.datatable.column(colID).header()).css('z-index', `${gridObj.columnDefs.length - colID}`);
          if(gridObj.fixedColumns){
            if((gridObj.columnDefs.length - colID) <= gridObj.fixedColumns.right){
              $(gridObj.datatable.column(colID).header()).css('z-index', `${(gridObj.columnDefs.length - colID + 1) * 10}`);
            }
          }
      });
      let rowFn = function ($event) {
        $event.stopImmediatePropagation();
        let row = gridObj.datatable.row(this);
        gridObj.$emit("rowclicked", gridObj.data.data[row.index()], row.index());
      }
      $("#"+this.id).off('click', 'tr', rowFn);
      $("#"+this.id).on('click', 'tr', rowFn);
    },
    onSelectionChanged(){
      //console.log("Grid Table 2");
      let values = Object.values(this.selectedRows);
      let selectionDiv = $("#"+this.id+"_wrapper .row-selection-div");
      if(values.length == 0){
        selectionDiv.html(``)
      }
      else{
        selectionDiv.html(`${values.length} row(s) selected.`)
      }
      //console.log("selection-changed values : ", values);
      this.$emit("selection-changed", values);
    },
    destoryAndDraw(){
      //console.log("Grid Table destoryAndDraw");
      this.datatable.destroy();
      $("#"+this.id).html("");
      this.dataTableInitialize();
    },
    redraw(){
      //console.log("Grid Table 4");
      this.datatable.draw();
    },
    redrawAllRows(){
      //console.log("Grid Table 5");
      this.donotcallserver = true;
      this.redraw();
    },
    destoryAndDrawWithData(arr){
      //console.log("Grid Table destoryAndDraw");
      this.datatable.destroy();
      $("#"+this.id).html("");
      this.search = {};
      this.defaultSearchStr = arr;
      this.dataTableInitialize();
    },
  }
}
</script>
<style lang="scss">
</style>
